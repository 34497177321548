import {
  FooterContainer,
  FooterWrapper,
  ItemSocial,
  ListSocial,
  Small,
} from "./styled";
import { Link } from "react-router-dom";

// Image
import icnSocial01 from "../../assets/Footer/icn_social_01.png";
import icnSocial02 from "../../assets/Footer/icn_social_02.png";
import icnSocial03 from "../../assets/Footer/icn_social_03.png";
import icnSocial04 from "../../assets/Footer/icn_social_04.png";
import nft_2 from "../../assets/Nfts/nft_img_01.png";
import nft_1 from "../../assets/Marketplace/nft_1.png";
import nft_3 from "../../assets/Marketplace/nft_3.png";
import { SwiperBlock, SwiperWrapper } from "../../page/Marketplace/styled";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <SwiperWrapper>
          {swiperData.map((item, index) => {
            return (
              <SwiperBlock>
                <figure>
                  <img src={item.img} alt="img" />
                </figure>
              </SwiperBlock>
            );
          })}
        </SwiperWrapper>
        <ListSocial>
          {listButtonSocial.map((item: any, index: number) => (
            <ItemSocial key={index}>
              <Link to={item.link}>
                <img src={item.icon} alt="Social" />
              </Link>
            </ItemSocial>
          ))}
        </ListSocial>
       
      </FooterWrapper>
    </FooterContainer>
  );
};

const listButtonSocial = [
  {
    icon: icnSocial01,
    link: "#",
  },
  {
    icon: icnSocial02,
    link: "#",
  },
  // {
  //   icon: icnSocial03,
  //   link: "#",
  // },
  // {
  //   icon: icnSocial04,
  //   link: "#",
  // },
];

const swiperData = [
  {
    img: nft_1,
  },
  {
    img: nft_2,
  },
  {
    img: nft_3,
  },
];

export default Footer;
