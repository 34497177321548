import styled from "styled-components";
import bg from "../../assets/Airdrop/airdrop_bg.jpg";
import airdrop_item_bg from "../../assets/Airdrop/airdrop_item_bg.png";
import item_title from "../../assets/Airdrop/item_title.png";
import item_content from "../../assets/Airdrop/item_content.png";
import { ConnectWalletButton } from "../../components/Common/ConnectWallet/styled";
import airdrop_turtie_1 from "../../assets/Airdrop/airdrop_turtie_1.png";
import airdrop_turtie_2 from "../../assets/Airdrop/airdrop_turtie_2.png";
import ButtonBG from '../../assets/Common/ConnectWallet/button_bg.png'

export const BuyIDOContainer = styled.div`
  position: relative;
  background: url(${bg}) no-repeat center / cover;
  min-height: 100vh;
  overflow: hidden;
`;
export const BuyIDOWrapper = styled.div`
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  padding: 150px 15px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    top: 150px;
    z-index: 0;
  }
  &::before {
    background-image: url(${airdrop_turtie_1});
    width: 186px;
    height: 204px;
    animation: TurtieFlow_1 7s linear infinite;
  }
  &::after {
    right: -200px;
    background-image: url(${airdrop_turtie_2});
    width: 180px;
    height: 222px;
    animation: TurtieFlow_2 10s linear infinite;
  }
  @keyframes TurtieFlow_1 {
    0% {
      left: -100%;
      top: -100%;
    }
    49% {
      opacity: 1;
    }
    50% {
      top: 400px;
      left: 150px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes TurtieFlow_2 {
    0% {
      right: -100%;
      top: -100%;
    }
    49% {
      opacity: 1;
    }
    50% {
      top: 400px;
      right: 120px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @media screen and (max-width: 767px) {
    width: unset;
  }
`;
export const BuyIDOTitle = styled.h2`
  max-width: 272px;
  width: 100%;
  margin: 0 auto 30px;
  img {
    width: 100%;
  }
`;
export const BuyIDOItemTitle = styled.div`
  display: flex;
  justify-content: center;
  background: url(${item_title}) no-repeat center / 100% 100%;
  width: calc(100% - 2px);
  min-height: 138px;
  border-top-right-radius: 34px;
  border-top-left-radius: 34px;
  h2 {
    padding-top: 20px;
    font-family: "Luckiest Guy", cursive;
    font-size: 19px;
    color: #fff;
  }
`;
export const BuyIDOContent = styled.div`
  margin-bottom: 30px;
  h3 {
    margin-top: -10px;
    margin-bottom: 20px;
    color: #003879;
    text-align: center;
    font-family: "Luckiest Guy", cursive;
    font-size: 23px;
  }
`;
export const BuyIDOInfo = styled.div`
  background: url(${item_content}) no-repeat center / 100% 100%;
  width: calc(100% - 60px);
  margin: 0 auto;
  padding: 20px;
  & > div {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    & > p {
      font-family: "Luckiest Guy", cursive;
      font-size: 16px;
      color: #fff;
    }
  }
`;
export const InputNumber = styled.div`
input {
  width: calc(100% - 60px);
  margin: 0 auto;
  padding: 20px;
  border: none;
  margin-top: 10px;
  border-radius: 6px;
  font-family: "Luckiest Guy", cursive;
  font-size: 18px;
}
    
 
`;
export const BuyIDOList = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 2;
  flex-wrap: wrap;
  & > li {
    max-width: 315px;
    width: 100%;
    padding: 10px;
    min-height: 424px;
    background: url(${airdrop_item_bg}) no-repeat center / 100% 100%;
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    
    &:hover {
      transform: scale(1.05);
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: calc(100% - 40px);
  }
  @media screen and (max-width: 767px) {
    width: unset;
  }
`;
export const BuyIDOAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 20px;
  margin-bottom: 15px;
  ${ConnectWalletButton} {
    color: #000;
    height: 40px;
    font-family: "Luckiest Guy", cursive;
    font-size: 18px;
  }
`;
export const BuyIDOCountTime = styled.p`
  color: #003879;
  font-family: "Luckiest Guy", cursive;
  font-size: 22px;
  margin: 0 auto;
`;


export const ButtonBuy = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 11px;
  border-radius: 8px;
  width: 126px;
  height: 58px;
  z-index: 1;
  font-family: 'PP-Pangaia-Medium-Bold';
  font-weight: 700;
  color: #000;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  margin: 0 auto;
  a {
    color: unset;
  }
  span {
    position: relative;
    top: -1px;
  }
  &::before {
    background: url(${ButtonBG}) center / contain no-repeat;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  @media screen and (max-width: 767px) {
    font-size: 15px;
    width: 100px;
    height: 45px;
    padding: 3px 10px 7px;
    span {
      top: 0;
    }
  }
`;


export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: blur(5px);
`;

export const ModalContainer = styled.div`
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
`;

export const ModalContent = styled.div`
  margin: 20px 0;
`;

export const ModalButton = styled.button<{ disabled?: boolean }>`
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 20px;
  opacity: ${({ disabled }) => (disabled && ("0.6"))};
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
  color: #fff;
  border: none;
  background: url(${item_content}) no-repeat center / 100% 100%;
    font-family: 'PP-Pangaia-Medium-Bold';
  font-weight: 700;
`;

export const ButtonCommon = styled.button<{ disabled?: boolean }>`
position: relative;
display: flex;
align-items: center;
justify-content: center;
border-radius: 8px;
width: 126px;
height: 50px;
font-size: 20px;
margin: 0 auto;
opacity: ${({ disabled }) => (disabled && ("0.6"))};
cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
color: ${({ disabled }) => (disabled ? "#fff" : "#000")};
border: none;
background: url(${ButtonBG}) center / contain no-repeat;
font-family: 'PP-Pangaia-Medium-Bold';
font-weight: 700;
// margin-bottom: 15px;
`;
