import Chart from "react-apexcharts";
import { ChartContainer, ChartWrapper } from "./styled";

const ChartTokenomic = ({ setUpdatePointIndex }: any) => {
  const options: any = {
    labels: [
      "Seed-Sale",
      "Private-Sale",
      "Public-Sale",
      "Liquidity",
      "CEX",
      "Staking",
      "Games",
      "Airdrops",
    ],
    chart: {
      type: "donut",
      events: {
        dataPointSelection: function (
          event: any,
          chartContext: any,
          config: any
        ) {
          setUpdatePointIndex(config.dataPointIndex + 1);
        },
      },
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "rgba(15, 73, 108,1)",
      "rgba(43, 118, 139,1)",
      "rgba(98, 161, 220,1)",
      "rgba(144, 200, 236,1)",
      "rgba(195, 231, 255,1)",
      "rgba(205, 231, 255,1)",
      "rgba(180,247,244)",
      "rgba(55,94,116)",
    ],
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 500,
        options: {
          chart: {
            width: 450,
          },
        },
      },
    ],
  };

  return (
    <ChartContainer>
      <ChartWrapper>
        <Chart
          options={options}
          series={[3, 5, 7, 45, 15,12, 8, 5]}
          type="donut"
          width={490}
        />
      </ChartWrapper>
    </ChartContainer>
  );
};

export default ChartTokenomic;
