import React, { useEffect, useState } from "react";
import {
  ButtonBuy,
  ButtonCommon,
  BuyIDOAction,
  BuyIDOContainer,
  BuyIDOContent,
  BuyIDOCountTime,
  BuyIDOInfo,
  BuyIDOItemTitle,
  BuyIDOList,
  BuyIDOTitle,
  BuyIDOWrapper,
  ModalContainer,
  ModalOverlay,
  ModalContent,
  ModalButton,
  InputNumber,
} from "./styled";
import web3 from "web3";
import title from "../../assets/Airdrop/airdrop_title.png";
import { convertFixed } from "../../utils/formatNumber";
import Countdown from "../../utils/countDown";
import abiBuyICO from "./../../constants/abis/abiBuyICO.json";
import { BUY_CONTRACT, USDT_CONTRACT } from "../../constants/index";
import { AbiItem } from "web3-utils";
import approve from "../../utils/approve";
import { useAccount, useNetwork } from "wagmi";
import getTokenInfo from "../../utils/checkInfoByAccount";
import { convertToWei } from "../../utils/convertNumber";
import { notiError, notiSuccess, notiWarning } from "../../utils/notication";
import Loading from "../../components/Loading";


declare const window: Window & typeof globalThis & { ethereum: any };
const BuyToken = () => {
  const Web3 = new web3(window.ethereum);
  const { address } = useAccount();
  const { chain }: any = useNetwork();
  const chainId: any = chain?.id;
  const currentTime = Math.floor(Date.now() / 1000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem]: any = useState(null);
  const [amount, setAmount] = useState(1);
  const [allowance, setAllowance] = useState<any>("");
  const [isApprove, setApprove] = useState<any>(false);
  const [isBuy, setBuying] = useState<any>(false);

  const buyTime = (startTime: any, endTime: any) => {
    if (currentTime < startTime) {
      return <Countdown timestamp={startTime} />;
    } else if (currentTime >= startTime && currentTime <= endTime) {
      return <Countdown timestamp={endTime} />;
    } else if (currentTime > endTime) {
      return <span>Ended!</span>;
    }
  };

  const status = (startTime: any, endTime: any) => {
    if (currentTime < startTime) {
      return "Upcoming";
    } else if (currentTime >= startTime && currentTime <= endTime) {
      return "Live";
    } else if (currentTime > endTime) {
      return "Ended";
    }
  };

  const handleBuyClick = (item: any) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getAllowance = async () => {
    try {
      const amountAllowance = await getTokenInfo(
        BUY_CONTRACT[chainId], USDT_CONTRACT, address
      );
      setAllowance(amountAllowance.allowance);
    } catch (error) {
      setAllowance("0");
      console.error("error", error);
    }
  };

  const handleApprove = async () => {
    try {
      setApprove(true);
      await approve(BUY_CONTRACT[chainId], USDT_CONTRACT, address).then(
        (res: any) => {
          if (res.status) {
            getAllowance();
            setApprove(false);
          }
        }
      );
    } catch (error) {
      setApprove(false);
      console.error(error);
    }
  };

  const handelBuyIDO = async (round: any) => {
    try {
      setBuying(true);
      const gasPrice = await Web3.eth.getGasPrice();
      const contract = new Web3.eth.Contract(
        abiBuyICO as unknown as AbiItem as AbiItem,
        BUY_CONTRACT[chainId]
      );
      const amountToWei = convertToWei(amount.toString(), 18);
      const gasEstimate = await contract.methods
        .buyToken(amountToWei.toString(), round)
        .estimateGas({
          from: address,
          to: BUY_CONTRACT[chainId],
          gasPrice: gasPrice,
        });
      await contract.methods
        .buyToken(amountToWei.toString(), round)
        .send({
          from: address,
          to: BUY_CONTRACT[chainId],
          gasPrice: gasPrice,
          gasEstimate: gasEstimate,
        })
        .then((res: any) => {
          setBuying(false);
          getAllowance();
          setIsModalOpen(false);
          notiSuccess('Buy token success,please check wallet', 5)
        })
        .catch((err: any) => {
          console.error("Err Buy", err);
          notiError(err.message, 5);
          setBuying(false);
        });

    } catch (error: any) {
      console.error("Error Buy", error);
      notiError(error.message, 5);
      setBuying(false);
    }
  };

  useEffect(() => {
    if (address || amount || chain?.id === 56) {
      getAllowance();
    }
  }, [address, amount, chainId]);

  return (
    <BuyIDOContainer>
      <BuyIDOWrapper>
        <BuyIDOTitle>
          <img src={title} alt="title" />
        </BuyIDOTitle>
        <BuyIDOList>
          {airdropData.map((item, index) => {
            const isDisabled = currentTime < item.startTime || currentTime > item.endTime;

            return (
              <li key={index}>
                <BuyIDOItemTitle>
                  <h2>{item.title}</h2>
                </BuyIDOItemTitle>
                <BuyIDOContent>
                  <h3>{status(item.startTime, item.endTime)}</h3>
                  <BuyIDOInfo>
                    <div>
                      <p>Price</p>
                      <p>${item.price}</p>
                    </div>

                    <div>
                      <p>Total Raise</p>
                      <p>${convertFixed(item.totalRaise)}</p>
                    </div>
                    <div>
                      <p>Total Supply</p>
                      <p>{convertFixed(item.totalSupply)} TUR</p>
                    </div>
                  </BuyIDOInfo>
                </BuyIDOContent>
                <BuyIDOAction>
                  <BuyIDOCountTime>{buyTime(item.startTime, item.endTime)}</BuyIDOCountTime>
                </BuyIDOAction>
                 <ButtonCommon disabled={currentTime >= item.startTime && currentTime > item.endTime || currentTime < item.startTime} onClick={() => handleBuyClick(item)}>
                    <span>Buy</span>
                  </ButtonCommon>
                
              </li>
            );
          })}
        </BuyIDOList>
      </BuyIDOWrapper>

      {isModalOpen && selectedItem && (
        <ModalOverlay>
          <ModalContainer>
            <BuyIDOList>
              <li>
                <BuyIDOItemTitle>
                  <h2>{selectedItem.title}</h2>
                </BuyIDOItemTitle>
                <BuyIDOContent>
                  <h3>Live</h3>
                  <BuyIDOInfo>
                    <div>
                      <p>Price</p>
                      <p>${selectedItem.price}</p>
                    </div>
                    <div>
                      <p>Min Buy</p>
                      <p>$1</p>
                    </div>
                    <div>
                      <p>Max Buy</p>
                      <p>$1000</p>
                    </div>

                  </BuyIDOInfo>
                  <InputNumber>
                    <input
                      type="number"
                      value={amount}
                      onChange={(e: any) => setAmount(e.target.value)}
                      min="1"
                      placeholder="Enter amount"
                    />
                  </InputNumber>

                </BuyIDOContent>
                <BuyIDOAction>
                  <BuyIDOCountTime>{buyTime(selectedItem.startTime, selectedItem.endTime)}</BuyIDOCountTime>
                </BuyIDOAction>

                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                  <ModalButton
                    disabled={
                      isBuy ||
                      isApprove
                    }
                    onClick={handleCancel}
                  >Cancel</ModalButton>

                  {chain?.id === 56 ? (
                    <>
                      {Number(allowance) >= Number(amount) ? (
                        <>
                          <ModalButton
                            disabled={
                              isBuy ||
                              !address ||
                              !amount ||
                              isApprove ||
                              amount < 1 || 
                              amount > 1000
                            }
                            onClick={() => {
                              handelBuyIDO(selectedItem.round)
                            }}
                          >
                            <Loading content="Confirm" status={isBuy} /></ModalButton>
                        </>
                      ) : (
                        <>
                          <ModalButton
                            disabled={
                              !address ||
                              isApprove ||
                              chainId !== 56
                            }
                            onClick={handleApprove}><Loading content="Approve" status={isApprove} /></ModalButton>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <ModalButton
                        disabled
                      >Switch Network</ModalButton>
                    </>
                  )}

                </div>
              </li>

            </BuyIDOList>

          </ModalContainer>
        </ModalOverlay>
      )}
    </BuyIDOContainer>
  );
};

const airdropData = [
  {
    round: 1,
    title: "Seed Sale",
    price: 0.0006,
    totalRaise: 20000,
    totalSupply: 30000000,
    startTime: 1733011200,
    endTime: 1735689600,
  },
  {
    round: 2,
    title: "Private Sale",
    price: 0.0009,
    totalRaise: 50000,
    totalSupply: 50000000,
    startTime: 1735689600,
    endTime: 1738368000,
  },
  {
    round: 3,
    title: "Public Sale",
    price: 0.0014,
    totalRaise: 98000,
    totalSupply: 70000000,
    startTime: 1738368000,
    endTime: 1740787200,
  },
];

export default BuyToken;
