import { message } from "antd";

const handleClose = () => {
  message.destroy();
};
const ContentSuccess = (data) => {
  return (
    <>
      <div className="content-common">
        <div className="content-cus">
          <div className="title-noti">Success!</div>
          <img
            src="../../../images/close.png"
            alt=""
            onClick={handleClose}
            style={{ padding: "3px" }}
          />
        </div>
        <p className="content-noti">{data}</p>
      </div>
    </>
  );
};
const ContentWarning = (data) => {
  return (
    <>
      <div className="content-common">
        <div className="content-cus">
          <div className="title-noti">Warning!</div>
          <img
            src="../../../images/close.png"
            alt=""
            onClick={handleClose}
            style={{ padding: "3px" }}
          />
        </div>
        <div style={{ maxHeight: "100px", overflowY: "auto" }}>
          <p className="content-noti">{data}</p>
        </div>
      </div>
    </>
  );
};
const ContentError = (data) => {
  return (
    <>
      <div className="content-common">
        <div className="content-cus">
          <div className="title-noti">Error!</div>
          <img
            src="../../../images/close.png"
            alt=""
            onClick={handleClose}
            style={{ padding: "3px" }}
          />
        </div>
        <div style={{ maxHeight: "100px", overflowY: "auto" }}>
          <p className="content-noti">{data}</p>
        </div>
      </div>
    </>
  );
};

export const notiSuccess = (content, duration) => {
  return new Promise((resolve) => {
    message.success({
      type: "success",
      content: ContentSuccess(content),
      className: "custom-class success",
      duration: duration,
    });
  });
};
export const notiWarning = (content, duration) => {
  return new Promise((resolve) => {
    message.warning({
      type: "warning",
      content: ContentWarning(content),
      className: "custom-class warning",
      duration: duration,
    });
  });
};
export const notiError = (content, duration) => {
  return new Promise((resolve) => {
    message.error({
      type: "error",
      content: ContentError(content),
      className: "custom-class error",
      duration: duration,
    });
  });
};
