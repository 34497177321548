import { Link, useLocation } from "react-router-dom";
import { ConnectWalletButton } from "./styled";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { shortenAddress } from "../../../utils/addressUser";

const ConnectWallet = () => {
  const { pathname } = useLocation();
  const { open } = useWeb3Modal();
  const { address } = useAccount();

  const ConnectWallet = () => {
    open();
  };

  return (
    <ConnectWalletButton
      onClick={() => pathname !== "/" && ConnectWallet()}
      style={{ width: pathname !== "/" ? "max-content" : "" }}
    >
      {pathname !== "/" ? (
        <span>
          {pathname !== "/"
            ? address
              ? shortenAddress(address)
              : "Connect Wallet"
            : "Buy Token"}
        </span>
      ) : (
        <Link to={"/buyToken"}>
          <span>
            {pathname !== "/"
              ? address
                ? shortenAddress(address)
                : "Connect Wallet"
              : "Buy Token"}
          </span>
        </Link>
      )}
    </ConnectWalletButton>
  );
};

export default ConnectWallet;
