import FishAnimation from "../../../components/FishSwimming";
import {
  KeyvisualContainer,
  KeyvisualText,
  KeyvisualTitle,
  KeyvisualWrapper,
} from "./styled";

const Keyvisual = () => {
  return (
    <KeyvisualContainer>
      <FishAnimation top="200px" bottom="unset" />
      <KeyvisualWrapper>
        <KeyvisualTitle>Hero Turtie</KeyvisualTitle>
        <KeyvisualText>
        A pioneering cryptocurrency project that combines the power of artificial intelligence with the resilience of turtles. With a mission to build a sustainable ecosystem, Turtie AI leverages cutting-edge blockchain technology to deliver smart, secure, and eco-friendly solutions. 
        </KeyvisualText>
      </KeyvisualWrapper>
      <FishAnimation reverse top="unset" bottom="400px" />
    </KeyvisualContainer>
  );
};

export default Keyvisual;
