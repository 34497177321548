import styled from "styled-components";

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 40px);
  background: rgba(255, 255, 255, 0.02);
  z-index: 5000;
`;
export const HeaderWrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: center;
  gap: 30px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: -1;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 20px;
  }
`;
export const HeaderLogo = styled.div`
  max-width: 164px;
  width: 100%;
  a {
    display: block;
    width: 84px;
  }
  img {
    width: 100%;
  }
`;
export const HeaderNavigation = styled.nav``;
export const NavigationNav = styled.ul`
  display: flex;
  align-items: center;
  gap: 24px;
  & > li {
    a {
      font-family: "Lost-Fish";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      transition: color 0.26s ease;
      color: #4f79c3;
      @media screen and (min-width: 1024px) {
        &.changed {
          color: #000;
          &.active {
            color: #fff;
          }
        }
      }

      &:hover {
        color: #fff;
      }
      &.active {
        color: #fff;
        font-size: 15px;
      }
    }
  }
  &.menu-fixed {
    position: fixed;
    justify-content: center;
    left: 0;
    top: 84px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    transition: transform 0.75s ease;
    transform: translateX(150%);
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 80px);
    backdrop-filter: blur(13px);
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    & > li {
      a {
        color: #4f79c3;
      }
    }
  }
`;
export const HeaderConnect = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonHamburger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  span {
    background-color: #fff;
    width: 100%;
    height: 2px;
    border-radius: 20px;
    display: inline-block;
    transition: all 0.26s ease;
    &:nth-child(2) {
      margin-top: 10px;
    }
  }
  &.active {
    span {
      position: relative;
      &:nth-child(1) {
        top: 2px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
        top: -11px;
      }
    }
  }
`;
