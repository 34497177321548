import React from 'react';
import Countdown from 'react-countdown';

const CountdownTimer = ({ timestamp }) => {

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Expired!</span>;
    } else {
      return (
        <div className="mint-remaning-countdown">
          <span>{days}d</span> : <span>{hours}h</span> :{" "}
          <span>{minutes}m</span> : <span>{seconds}s</span>
        </div>
      );
    }
  };

  return <Countdown date={Date.now() + (timestamp - new Date().getTime() / 1000) * 1000} renderer={renderer} />;
};

export default CountdownTimer;
