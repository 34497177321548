import { useState } from "react";
import { ChartItem, ChartNumber, ChartTitle, ChartValue } from "./Chart/styled";
import {
  ChartBlock,
  ChartImage,
  TokenomicContainer,
  TokenomicTitle,
  TokenomicValue,
  TokenomicWrapper,
} from "./styled";
import ChartTokenomic from "./Chart";

// Image
import chartLogo from "../../../assets/Tokenomic/chart_logo.png";
// import tokenomic_value from "../../../assets/Tokenomic/tokenomic_value.png";
import tokenomic_value from "../../../assets/Tokenomic/supply.png";

const Tokenomic = () => {
  const [updatePointIndex, setUpdatePointIndex] = useState(1);

  return (
    <TokenomicContainer>
      <TokenomicWrapper>
        <TokenomicTitle>Tokenomics</TokenomicTitle>
        <ChartValue>
          {preSaleData.map((item, index) => {
            return (
              <ChartItem
                className={item.id === updatePointIndex ? "visible" : ""}
                key={index}
              >
                <ChartTitle>{item.title}</ChartTitle>
                <ChartNumber>{item.value} %</ChartNumber>
              </ChartItem>
            );
          })}
        </ChartValue>
        <ChartBlock>
          <ChartImage>
            <img src={chartLogo} alt="chart_logo" />
          </ChartImage>
          <ChartTokenomic setUpdatePointIndex={setUpdatePointIndex} />
        </ChartBlock>
        <TokenomicValue>
          <img src={tokenomic_value} alt="tokenomic_value" />
        </TokenomicValue>
      </TokenomicWrapper>
    </TokenomicContainer>
  );
};

const preSaleData = [
  {
    id: 1,
    title: "Seed-Sale",
    value: "3",
  },
  {
    id: 2,
    title: "Private-Sale",
    value: "5",
  },
  {
    id: 3,
    title: "Public-Sale",
    value: "7",
  },
  {
    id: 4,
    title: "Liquidity",
    value: "45",
  },
  {
    id: 5,
    title: "CEX",
    value: "15",
  },
  {
    id: 6,
    title: "Staking",
    value: "12",
  },
  {
    id: 7,
    title: "Games",
    value: "8",
  },
  {
    id: 8,
    title: "Airdrops",
    value: "5",
  },
];

export default Tokenomic;
