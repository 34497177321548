import { Link, useLocation } from "react-router-dom";
import {
  ButtonHamburger,
  HeaderConnect,
  HeaderContainer,
  HeaderLogo,
  HeaderNavigation,
  HeaderWrapper,
  NavigationNav,
} from "./styled";
import logo from "../../assets/Header/logo.png";
import ConnectWallet from "../Common/ConnectWallet";
import { NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../Context";

const Header = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [openHamburger, setOpenHamburger] = useState(false);
  const { pathname } = useLocation();
  const handleOpenHamburger = () => {
    setOpenHamburger(!openHamburger);
  };

  useEffect(() => {
    if (openHamburger) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openHamburger]);

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderLogo>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </HeaderLogo>
        <NavigationNav
          className={!isDesktop ? "menu-fixed" : ""}
          style={
            !isDesktop && openHamburger ? { transform: "translateX(0)" } : {}
          }
        >
          {navData.map((item, index) => {
            return (
              <li
                onClick={() => {
                  setOpenHamburger(false);
                }}
                key={index}
              >
                <NavLink
                  className={
                    pathname === "/chat" || pathname === "/marketplace"
                      ? "changed"
                      : ""
                  }
                  to={item.href}
                >
                  {item.title}
                </NavLink>
              </li>
            );
          })}
        </NavigationNav>
        <HeaderConnect>
          <ConnectWallet />
        </HeaderConnect>
        {!isDesktop && (
          <ButtonHamburger
            className={openHamburger ? "active" : ""}
            onClick={() => {
              handleOpenHamburger();
            }}
          >
            <span></span>
            <span></span>
          </ButtonHamburger>
        )}
      </HeaderWrapper>
    </HeaderContainer>
  );
};

const navData = [
  {
    title: "HOME",
    href: "/",
  },
  {
    title: "AI CHAT",
    href: "/chat",
  },
  {
    title: "Buy Now",
    href: "/buyToken",
  },
];

export default Header;
