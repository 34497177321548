import Home from "./page/Home";
import { AppContainer } from "./styled";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextProvider, ContextProviderWrapper } from "./components/Context";
import { Toaster } from "react-hot-toast";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { bsc } from "viem/chains";
import { useContext, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Nfts from "./page/Nfts";
import Airdrop from "./page/Airdrop";
import BuyIDO from "./page/BuyIDO";
import Chat from "./page/Chat";
import Marketplace from "./page/Marketplace";
import BubblesBackground from "./components/Bubbles/Canvas";

const projectId = "3a1eba421630b4028ff59600db61d251";
const chains = [bsc];
const wagmiConfig: any = defaultWagmiConfig({ chains, projectId });
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "light",
  themeVariables: {
    "--w3m-z-index": 9999,
  },
});

const App = () => {
  const { pathname } = useLocation();
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <WagmiConfig config={wagmiConfig}>
      <Toaster position="top-right" reverseOrder={false} />
      <AppContainer>
        {((pathname === "/" && isDesktop) ||
          (pathname === "/marketplace" && isDesktop) ||
          (pathname === "/chat" && isDesktop)) && (
          <>
            <BubblesBackground />
          </>
        )}
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/buyToken" element={<BuyIDO />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {pathname === "/" && <Footer />}
      </AppContainer>
    </WagmiConfig>
  );
};

export default App;
