import Web3 from "web3";
import {convertFromWei} from "./convertNumber";
import abiToken from "./../constants/abis/abiToken.json";
const web3 = new Web3(window.ethereum);


async function getTokenInfo(contractAddress,tokenAddress,accountAddress) {
  const contract = new web3.eth.Contract(abiToken,
    tokenAddress
  );

  try {
     const name = await contract.methods.name().call()
     const decimals = await contract.methods.decimals().call()
     const totalSupply = await contract.methods.totalSupply().call()
     const symbol = await contract.methods.symbol().call()
     const balanceOf = await contract.methods.balanceOf(accountAddress).call()
     const allowance = await contract.methods.allowance(accountAddress,contractAddress).call()

    const supply = await convertFromWei(totalSupply,Number(decimals))
    const balanceOfFromWei = await convertFromWei(balanceOf,Number(decimals))
    const allowanceFromWe = await convertFromWei(allowance,Number(decimals))

    return {
      name,
      decimals: Number(decimals),
      address: tokenAddress,
      supply: supply,
      symbol,
      balanceOf: balanceOfFromWei,
      allowance: allowanceFromWe
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default getTokenInfo
